(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockCountDatas', {
        templateUrl: 'views/components/views/stockCountDatas/stockCountDatas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'stockCountDatasService', 'rememberService', function ($stateParams, $q, stateService, utilityService, stockCountDatasService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockCountKeyno = $stateParams.stockcount_keyno;

            let variableNames = {
                gb_countstatus: '',
                searchtext: ''
            };

            vm.model = {
                bgCountStatus: {
                    selected: 'APPROVED',
                    buttons: [
                        { id: 'APPROVED', label: 'gb_countstatus_approved', color: 'primary', onClick: () => setCountStatusView('APPROVED') },
                        { id: 'NOTAPPROVED', label: 'gb_countstatus_notapproved', color: 'primary', onClick: () => setCountStatusView('NOTAPPROVED') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchStockCountDatasList() }
                ],
                setting: {},
                itemsListStockCountDatas: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setCountStatusView = function (id) {
                vm.model.bgCountStatus.selected = vm.model.setting.gb_countstatus = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            let loadSetting = function () {
                let deferred = $q.defer();

                stockCountDatasService.getSetting({ stockcount_keyno: stockCountKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setCountStatusView(vm.model.setting.gb_countstatus);

                    $q.all([
                        loadStockCountDatas()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockCountDatas = function () {
                if (utilityService.validateParmsValue(vm.model.setting.gb_countstatus) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.pageno) !== true) return;

                vm.model.itemsListStockCountDatas = [];

                return stockCountDatasService.listStockCountDatas(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockCountDatas);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_countstatus':
                        if (utilityService.validateParmsValue(vm.model.setting.gb_countstatus) !== true) return;

                        variableValue = vm.model.setting.gb_countstatus;
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';
                        vm.model.setting.pageno = '1';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadStockCountDatas();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCountStatus = function (value) {
                vm.model.bgCountStatus.selected = value;
                vm.model.setting.gb_countstatus = vm.model.bgCountStatus.selected;

                rememberFunc('gb_countstatus');
                loadStockCountDatas();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.searchStockCountDatasList = function () {
                rememberFunc('searchtext');
                loadStockCountDatas();
            };

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') {
                            rememberFunc('searchtext');
                            loadStockCountDatas();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadStockCountDatas();
                        }
                        break;
                    case 'pageno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.pageno) !== true) return;

                        vm.model.setting.pageno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.pageno, true) !== true) return;

                        loadStockCountDatas();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
