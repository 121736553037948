(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCountRefresh', {
        templateUrl: 'views/components/views/stockCountRefresh/stockCountRefresh.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'stockCountRefreshService', function ($stateParams, stateService, stockCountRefreshService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let stockCountKeyno = $stateParams.stockcount_keyno;
            
            vm.model = {
                edit: {},
                refreshing: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                stockCountRefreshService.getEdit({ stockcount_keyno: stockCountKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region REFRESH BUTTON FUNCTION
            
            vm.refreshChanges = function () {
                vm.model.refreshing = true;

                stockCountRefreshService.refreshStock({ stockcount_keyno: vm.model.edit.stockcount_keyno }).then(function () {
                    vm.model.refreshing = false;

                    stateService.back();
                }, function () {
                    vm.model.refreshing = false;
                });
            };

            // #endregion REFRESH BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
