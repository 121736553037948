(function () {
    'use strict';

    angular.module("imApp").factory("stockCountMenuService", ['$ihttp', function ($ihttp) {
        let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 952,
                    parameters: parms || {}
                });
            },
            approveCounting: function (menu) {
                return $ihttp.post({
                    method: 962,
                    parameters: menu
                });
            },
            fetchDataMiningItem: function (menu) {
                return $ihttp.post({
                    method: 958,
                    parameters: menu
                });
            },
            deleteItem: function (menu) {
                return $ihttp.post({
                    method: 955,
                    parameters: menu
                });
            }
        };

        return service;
    }]);
})();
