(function () {
    'use strict';

    angular.module("imApp").factory("stockCountsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 950,
                    parameters: parms || {}
                });
            },
            listStockPlaces: function (parms) {
                return $ihttp.post({
                    method: 940,
                    parameters: parms || {}
                });
            },
            listStockCounts: function (parms) {
                return $ihttp.post({
                    method: 951,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
