(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCounts', {
        templateUrl: 'views/components/views/stockCounts/stockCounts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'stockCountsService', 'rememberService', function ($q, stateService, utilityService, stockCountsService, rememberService) {
            
            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            
            let variableNames = {
                stockplace_id: ''
            };
            
            vm.model = {
                bgStockCountStatus: {
                    selected: 'OPEN',
                    buttons: [
                        { id: 'OPEN', label: 'bg_stockcount_status_open', color: 'primary', onClick: () => vm.model.bgStockCountStatus.selected = 'OPEN' },
                        { id: 'CLOSED', label: 'bg_stockcount_status_closed', color: 'primary', onClick: () => vm.model.bgStockCountStatus.selected = 'CLOSED' }
                    ]
                },
                setting: {},
                selectListStockPlaces: [],
                itemsListStockCounts: []
            };
            
            // #endregion VARIABLES & DEFINITIONS

            let loadSetting = function () {
                const deferred = $q.defer();
                
                stockCountsService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadStockCounts()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockPlaces = function () {
                stockCountsService.listStockPlaces().then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };
            
            let loadStockCounts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;
                
                vm.model.itemsListStockCounts = [];
                
                return stockCountsService.listStockCounts(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockCounts);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'stockplace_id':
                            variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
    		// #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockCountStatus = function (value) {
                vm.model.bgStockCountStatus.selected = value;
                vm.model.setting.bg_stockcount_status = vm.model.bgStockCountStatus.selected;

                loadStockCounts();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockcount':
                        go({
                            stockplace_id: vm.model.setting.stockplace_id,
                            stockcount_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');
                        loadStockCounts();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadStockPlaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
