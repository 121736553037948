(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCountMenu', {
        templateUrl: 'views/components/views/stockCountMenu/stockCountMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'stockCountMenuService', function ($stateParams, stateService, translateService, modalService, stockCountMenuService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let stockCountKeyno = $stateParams.stockcount_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                menu: {},
                approving: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATION

            // #region LOAD PROCEDURE CALL
            
            let loadMenu = function () {
                stockCountMenuService.getMenu({ stockcount_keyno: stockCountKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            // #endregion LOAD PROCEDURE CALL

    		// #region APPROVE BUTTON FUNCTION
    		
            vm.approveChanges = function () {
                vm.model.approving = true;
                
                stockCountMenuService.approveCounting(vm.model.menu).then(function () {
                    vm.model.approving = false;

                    loadMenu();
                }, function () {
                    vm.model.approving = false;
                });
            };

            // #endregion APPROVE BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockcountapprove':
                        go({ stockcount_keyno: vm.model.menu.stockcount_keyno });
                        break;
                    case 'dataMining':
                        stockCountMenuService.fetchDataMiningItem(vm.model.menu).then(function (response) {
                            state = response[0].item_state;
                            
                            go(response[0].item_parms);
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    stockCountMenuService.deleteItem(vm.model.menu).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
